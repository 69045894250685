



































import { Component, Vue } from "vue-property-decorator";
import { clientList,signClient } from "@/serve/healthFile/healthFile";


import Assignment from "./component/assignment.vue";
@Component({
  name: "DiscountManagement",
  components: { Assignment },
})
export default class DiscountManagement extends Vue {
  public clientUserList = [];
  public created() {
    this.clientListApi();
    
  }
  // api相关
  // 管理医生所辖用户列表
  public async clientListApi(){
    const params = {
      userId: JSON.parse(sessionStorage.getItem("Token") as string).user_id
     
    };
    const res = await clientList(params);
    console.log("res",res);
    
    this.clientUserList = res.data

  }
  public async sirchUpdata(e: any){
    console.log("e",e);
    
    const {id,userId} = e
    const data: any = {
      doctorUserId:JSON.parse(sessionStorage.getItem("Token") as string).user_id,
      clientUserId:userId,
      "type": "message" 
    }
    const res = await signClient(data);
    if (res.code === 200) {
      this.$message({
          message: '更新成功！',
          type: 'success'
        });
        this.clientListApi()
    }else{
      this.$message.error(res.msg);
    }
    
  }
}
